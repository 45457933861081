@import 'poscat-vars';
@import 'poscat-web-mqs-2';

@include palette();

@import 'poscat-commons';

@import 'imagery/icono/icons';
@import 'imagery/icono/icono-poscat-logotype';
@import 'imagery/icono/icono-chevrons';
@import 'imagery/icono/icono-cue-multiplier';
@import 'imagery/icono/icono-cue-flashing-star';
@import 'imagery/icono/icono-cue-fileformat';
@import 'imagery/icono/icono-3rd-parties';
@import 'imagery/icono/icono-popsicle';

@import 'components/cues';
@import 'components/pastille';
@import 'components/dot-loader';
@import 'components/notifier';
@import 'components/cta-getter';
@import 'components/ui-button';
@import 'components/cover';
@import 'components/rhythm-selector';
@import 'components/single-work';
@import 'components/distribution';

@import 'label/label-size';

@import 'layout/layout-particles';
@import 'layout/layout-cover';
@import 'layout/layout-forms';
@import 'layout/column';
@import 'layout/pages';
@import 'layout/page-homepage';
@import 'layout/page-work';
@import 'layout/page-root-catalogue';
@import 'layout/layout-informative';
@import 'layout/layout-fonts';
@import 'layout/layout-backdrop';

/* stylelint-disable indentation*/

.pg-first {
	@include _tall-page {
		height: $first-page-size-upper;
		height: unquote('calc( 100% - #{2 * $chevron-size} - #{$double-gutter} )');
		margin-top: unquote('calc( #{$chevron-size} + #{$gutter} )');
	}

	@include MEDIA-not-tall-enough {
		margin-top: $half-gutter;
	}
}

/*.pg-first {
	@include MEDIA-XL-and-above {
		margin-top: ( 100% - $first-page-size-upper ) / 2;
	}
}*/

.pg-what {
	@include _tall-page {
		height: 80%;
	}
}

.pg {
	margin: 0;
	padding: 0;

	justify-content: flex-start;
	align-items: stretch;
	flex-wrap: nowrap;

	@include MEDIA-XL-and-above {
		flex-direction: row;
	}

	@include MEDIA-XL-and-below {
		flex-direction: column;
	}
}

.pg-first {
	@include MEDIA-XL-and-below {
		& + .pg {
			margin-top: 50%;
		}
	}
}

.pg-first_bl-left,
.pg-first_bl-right {
	@include MEDIA-XL-and-above {
		height: 100%;
		width: 50%;
	}

	@include MEDIA-XL-and-below {
		width: 100%;
	}

	@include MEDIA-mobile-breakpoint {
		// height: 100vw; // NOT working on iOS
	}
}

.pg-first_bl-left {
	align-self: center;

	@include MEDIA-XL-and-below {
		flex-direction: column;
		margin-bottom: 5rem;
	}

	@include MEDIA-not-tall-enough {
		margin-bottom: 5rem;
	}

	& > .el {
		align-self: stretch;
		justify-content: center;
	}
}

.pg-first_bl-right {
	position: relative;

	@include MEDIA-XL-and-above {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}

.el-header {
	margin: auto;

	@include MEDIA-XL-and-above {
		flex-direction: row;
		width: 100%;
	}

	@include MEDIA-XL-and-below {
		flex-direction: column;
		height: 100%;
	}

	@include MEDIA-not-tall-enough {
		margin-top: $double-gutter;
	}
}

.item-main-title {
	position: relative;
	align-items: center;
	order: 2;

	@include MEDIA-XL-and-above {
		margin-left: $large-gutter;
	}

	@include MEDIA-XL-and-below {
		margin-top: $double-gutter;
	}

	@include MEDIA-mobile-breakpoint {
		flex-direction: column;
	}
}

.item-logotype {
	order: 1;
}

#poscat-title {
	@extend %_font-headings;
	display: block;
	margin: 0;
	cursor: default;
}

%_placeholder {
	color: $grey-3a;
	font-style: italic;
	font-weight: 100;
}

input::-webkit-input-placeholder {
	@extend %_placeholder;
}

::-webkit-input-placeholder {
	@extend %_placeholder;
}

::-moz-placeholder {
	@extend %_placeholder;
	color: $grey-3a;
}

:-ms-input-placeholder {
	@extend %_placeholder;
}

::placeholder {
	@extend %_placeholder;
}

form {
	&:invalid {
		.unchecked {
			@extend %_check-circle-default;
		}
	}

	&:valid {
		.unchecked {
			@extend %_check-circle-checked;
		}
	}

	@include MEDIA-L-and-below {
		display: block;
	}
}

/*
	SPECS
	*/

.specs {
	font-size: get-regular-font-size();
}

.specs-title {
	@extend %_font-headings;
	font-size: get-font-size(1.5);
	margin: 0;
}

.specs-title {
	//.specs-author{
	color: $grey-4;
}

.specs-details {
	width: 100%;
}

.specs-author {
	font-weight: 100;
	font-style: normal;
}

.specs-length {
	//font-size: get-font-size( .7 );
}

.specs-details,
.specs-length {
	display: block;
	margin-top: $half-gutter;
}

// BUTTON

.button {
	// regarding its parent
	align-self: center;
	// props for its children
	//display: flex;
	align-items: center;
	justify-content: center;
	// styles for self
	width: 24px;
	height: 40px;
	border-radius: 3px;
	border: 1px solid $dark-grey;
	cursor: pointer;
}

.svg-dropdown {
	transform: rotate(0deg); /* stylelint-disable-line unit-whitelist */
	transition: transform 200ms 20ms;
	will-change: transform;
}

.opened .svg-dropdown {
	transform: rotate(180deg); /* stylelint-disable-line unit-whitelist */
}

path.dropdown {
	fill: $dark-grey;
}

.disabled .button {
	border-color: $grey-3a;
}

.disabled .dropdown {
	fill: $grey-3;
}

#subscribe-feedback {
	display: none;
	padding: $gutter;
	background-color: $color-white-apex;
	border-radius: $half-gutter;
	opacity: 0;
	visibility: hidden;
	align-self: flex-start;
	z-index: 3;

	&.displayed {
		visibility: visible;
	}

	.sub-feedback {
		//display: flex;
		flex-direction: row;
		justify-content: center;
	}

	&,
	.pastille {
		cursor: default;
	}

	.pastille {
		align-self: center;
	}
}

/*
	DECORATOR
	*/

.selector-help {
	@include MEDIA-XL-and-above {
		margin-left: $gutter + $double-gutter;
	}

	@include MEDIA-XL-and-below {
		order: 1;
		margin-top: $half-gutter;
	}
}

#link-how-it-works {
	@extend %_font-sans-serif;
	@include underline;
	position: relative;
	height: 100%;
	padding-top: $half-gutter;
	padding-bottom: $half-gutter;
	font-weight: 700;
	color: $color-solid;
	background: none;
	cursor: pointer;
}

.decorator-chevron {
	margin: auto;
	padding-bottom: $double-gutter;
	z-index: 2;

	&,
	&-container {
		flex-direction: row;
		align-items: center;
		justify-content: center;

		@include MEDIA-XL-and-below {
			flex-direction: column;
		}
	}

	&-container {
		@include MEDIA-XL-and-above {
			transform: translateX(50%); // centers chevron + label on screen center
		}

		&-svg {
			@include MEDIA-XL-and-above {
				transform: translateX(-50%); // centers the button on the real midpoint
			}

			@include MEDIA-XL-and-below {
				order: 2;
				margin-top: $half-gutter;
			}
		}
	}

	@include MEDIA-XL-and-above {
		// position: absolute;
		width: 100%;
		// bottom: $half-gutter;
	}

	@include MEDIA-XL-and-below {
		// position: absolute;
		width: 100%;
		// bottom: 1rem;
	}

	@include MEDIA-not-tall-enough {
		padding-bottom: $half-gutter;
	}

	.chevron {
		display: block;
		width: $chevron-size;
		height: $chevron-size;
		margin: auto;
		cursor: pointer;

		transform: rotateZ(0deg); /* stylelint-disable-line unit-whitelist */
		transition: transform 150ms ease-out;
		will-change: transform;

		&.scrolled {
			transform: rotateZ(180deg); /* stylelint-disable-line unit-whitelist */
		}
	}

	.flat-element {
		fill: #333;
	}

	.circle {
		stroke-width: 2px;
		stroke: $black;
		fill: $color-soft;
	}
}

/*
	PG-WHAT
	*/

.pg-what {
	background-color: $grey-5;
	padding: 3rem 0;

	.bl {
		//@include display( flex );
		flex-direction: column;
		justify-content: center;

		@include MEDIA-XL-and-above {
			width: 50%;
			padding: 0 4rem;
		}

		@include MEDIA-XL-and-below {
			width: 100%;
			padding: 0 2rem;
		}
	}

	.rationale {
		color: $grey-3;
	}

	&.beta {
		background-color: #aafcb8; //#F7BFB4;//#6E7271;
		.rationale {
			color: $grey-7;
		}

		.question {
			color: $grey-6;
		}
	}
}

#reading-what {
	width: 100%;
	height: auto;
	color: $grey-4;
	background-color: #edffab;
	.pg-what_bl {
		width: 100%;
		//max-width: 50rem; // on mobile
	}

	.work-about {
		font-weight: 300;
		font-size: 2em;
		color: $grey-6;
	}

	.work-title,
	.work-author {
		font-weight: 300;
		color: $grey-6;
	}

	.work-chapter {
		display: block;
		columns: 400px, 2;
		column-gap: 2em;
	}

	.extrait {
		margin-left: 0;
		margin-right: 0;
	}
}

.bl.pg-what_bl-right {
	@include MEDIA-XL-and-above {
		padding-left: 1rem;
	}
}

.question {
	font-weight: 300;
	font-size: 2em;
	color: $color-soft;
}

.bottom-subscribe-link {
	cursor: pointer;
}

#HW_badge_cont {
	position: absolute !important;
	right: -2rem;
	bottom: 2rem;
}

#HW_badge {
	background: $blue !important;

	&.HW_softHidden {
		background: mix($color-soft, $color-solid, 70%) !important;
	}
}

/*stylelint enable*/
