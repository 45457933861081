.cue-fileformat {
	width: 16px;
	height: 16px;

	.flat-element {
		fill: currentColor;
	}

	.medium-element {
		opacity: 0.8;
		fill: currentColor;
	}
}

.cta-getter {
	.cue-fileformat {
		transform: scale(1.3);
	}
}
