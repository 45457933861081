$half-gutter: 1em !default;
$gutter: 1em !default;
$double-gutter: 1em !default;
$color-solid: hotpink !default;
$color-white: white !default;
$color-white-apex: white !default;
$grey-5: grey !default;
$grey-7: grey !default;

ui-button {
	opacity: 0;
}

[type='submit'],
.ui-button {
	display: inline-block;
	border-radius: $half-gutter; //1em;
	width: auto;
	margin: 0;
	padding: $half-gutter $double-gutter;
	font-weight: 300;

	color: white;

	background-color: $grey-5;
	border: 1px solid $grey-7;

	cursor: pointer;
}

.ui-button {
	&:any-link {
		text-decoration: none;
	}

	&.update-rhythm-button {
		margin-left: $double-gutter;
	}

	&-label + .dot-loader {
		display: none;
	}

	&[data-state='disabled'],
	&[data-state='pending'] {
		cursor: default;
	}

	&[data-state='active'] {
		cursor: pointer;
	}
}

[data-state='disabled'] {
	.ui-button-label {
		opacity: 0.5;
	}
}

[data-state='pending'] {
	.ui-button-label {
		display: none;
	}

	.ui-button-label + .dot-loader {
		display: block;
	}
}

form:invalid .ui-button,
.ui-button[data-state='disabled'] {
	$color-bk-button: $color-white-apex;
	color: mix($grey-5, $color-bk-button, 40%);
	background-color: $color-bk-button;
	border-color: mix($grey-5, $color-bk-button, 40%);
	cursor: default;
}
