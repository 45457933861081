$single-width--small: unquote('calc(100% - #{$double-gutter})');

@keyframes chevronReveal {
	0% {
		transform: translateX(-$half-gutter);
		opacity: 0;
	}

	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes chevronConceal {
	0% {
		transform: translateX(0);
		opacity: 1;
	}

	100% {
		transform: translateX(-$half-gutter);
		opacity: 0;
	}
}

#structure.is-catalogue {
	position: inherit;
}

.is-page-body {
	display: grid;

	@include MEDIA-L-and-above {
		grid-template-columns: 60px 300px 120px 1fr 60px;
		grid-template-rows: 100px 1fr auto 200px;
		grid-template-areas: 'sidebar sidebar sidebar . .' 'sidebar sidebar sidebar upper .' 'body body body body .' 'footer footer footer footer footer';
	}

	@include MEDIA-L-and-below {
		grid-template-columns: 60px auto 60px;
		grid-template-rows: 100px 1fr auto 200px;
		grid-template-areas: 'sidebar sidebar .' 'sidebar sidebar upper' 'body body body' 'footer footer footer';
	}

	& > ⭐-part {
		grid-column-start: gauche;
	}

	.heading {
		grid-area: sidebar;
		z-index: 1;
	}

	.works {
		margin-top: $large-gutter;
		grid-area: body;
		display: grid;
		grid-column-gap: $double-gutter;
		grid-row-gap: $double-gutter;

		@include MEDIA-L-and-above {
			grid-template-columns: repeat(auto-fill, $single-width);
		}

		@include MEDIA-L-and-below {
			grid-template-columns: $single-width--small;
			justify-content: center;
		}
	}
}

.catalogue {
	&-headings {
		@include MEDIA-L-and-above {
			padding-left: $large-gutter;
		}

		@include MEDIA-L-and-below {
			padding-left: $gutter;
			padding-right: $gutter;
		}
	}

	&-title {
		@include MEDIA-L-and-above {
			white-space: nowrap;
		}
	}

	&-description {
		margin-bottom: $gutter;
	}
}

.works {
	@include MEDIA-L-and-above {
		padding: $large-gutter $large-gutter;
	}

	&.as-body {
		width: 100%;
	}
}

.cover-300x400 {
	width: 300px;
	height: 400px;
}
