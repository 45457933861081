@keyframes icon-popsicle-anim-arrows {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.icon-popsicle {
	&-arrows {
		transform-origin: center;
		will-change: transform;
		animation: icon-popsicle-anim-arrows 3s 0s cubic-bezier(0.125, 0.865, 0.855, 0.825) infinite;
	}
}
