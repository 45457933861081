/*
  _____

  METRICS
  _____
*/

$gutter: 1rem;
$micro-gutter: .1rem;
$half-gutter: $gutter / 2;
$double-gutter: $gutter * 2;
$large-gutter: $gutter * 4;
