$color-white: hotpink !default;
$color-solid: hotpink !default;
$half-gutter: 1em !default;

.component.dot-loader {
	display: inline-block;
	width: auto;
}

.ui-button {
	circle.flat-element {
		fill: $color-white;
	}
}

@keyframes dots {
	0% {
		transform: scale(1);
		opacity: 1;
	}
	50% {
		transform: scale(0.05);
		opacity: 0;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
}

.dot-small {
	width: $half-gutter;
	height: $half-gutter;

	.flat-element {
		fill: $color-solid;
		transform-origin: center center;
		animation: dots 1s cubic-bezier(0, 0.49, 1, 0.35) infinite;
		will-change: transform, opacity;
	}

	& + & {
		margin-left: $micro-gutter;
	}

	&.bis {
		.flat-element {
			animation-delay: 0.2s;
		}
	}

	&.ter {
		.flat-element {
			animation-delay: 0.7s;
		}
	}
}
