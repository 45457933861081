/*
_____

A DESIGN SYSTEM
_____

Dependencies
  CHROMATIC
  https://github.com/maxluster/chromatic-sass/blob/master/api.md
*/

@import 'utils/_utils';

@import 'structure/_structure';
@import 'structure/structure.metrics';
@import 'structure/structure.colors';
@import 'structure/structure.typesetting';

@import 'layout/_layout'; // I am :root
@import 'layout/layout.groups';

@import 'themes/_themes';


/*
  Once there, any library may be influenced by custom values from themes
  Please note that some basic transitions beside "css reset" may
  already happened at this point and may impacts the elements below
  (e.g. structure.typesetting which impacts both headings and paragraphs)
*/
@import 'content/content.headings';
@import 'content/content.chapters';
@import 'content/content.paragraphs';
@import 'content/content.footers';
@import 'content/content.pre';
@import 'content/content.small';
