.party {
	left: 0;
	top: 0;
	width: 100%;
	min-height: 100%;
	pointer-events: none;
	z-index: 10;
	mix-blend-mode: soft-light;

	&,
	&-particle {
		position: absolute;
	}
}

.pond {
	fill: var(--accent-color, $color-white);
	opacity: 1;
}
