$cta-getter-width: 280px;
$cta-getter-height: 48px;

/*
@keyframes ctaGetterPendingAnimation {
	0% {
		transform: scaleX(0) translateX(0);
	}
	50% {
		transform: scaleX(1) translateX(0);
	}
	100% {
		transform: scaleX(1) translateX(280px);
	}
}
*/

%_cta-getter {
	color: $color-white;
	background-color: var(--text-color, $color-solid);
	border-radius: 4px;
	height: $cta-getter-height;
	max-width: $cta-getter-width;
	margin-top: $half-gutter;
	cursor: pointer;

	&.is-minor {
		width: unquote('calc(50% - #{$half-gutter})');
	}

	&.is-ternary {
		color: var(--text-color, $color-solid);
		background-color: $color-white;
		border: 1px solid currentColor;
	}

	&.is-featured {
		background-color: $color-action;
	}
}

.cta-getter {
	@extend %_cta-getter;

	&-effects {
		position: absolute;
		transform: translate(-0.4rem, -0.8rem);
	}

	&-icon {
		width: $cta-getter-height;
		min-width: $cta-getter-height;
		height: $cta-getter-height;
		align-items: center;
		justify-content: center;
		z-index: 5;
	}

	&-label {
		align-items: center;
		line-height: $cta-getter-height;
		width: 100%;
		text-align: center;
		font-size: 1rem;
		transform: translateX(-18px);
		overflow: hidden;
	}

	.popsicle-stars {
		opacity: 0;
	}

	&:hover {
		.popsicle-stars {
			opacity: 1;
		}

		.lens,
		.star {
			opacity: 0;
		}

		.icon-chevron-regular {
			animation: chevronReveal 50ms 500ms forwards;
		}

		@extend %flashing-star-animations;
	}

	/*&::before {
		// is-awaiting
		position: absolute;
		content: ' ';
		width: $cta-getter-width;
		height: $cta-getter-height;
		background-color: red;
		transform-origin: left;
		animation: ctaGetterPendingAnimation 1s infinite normal ease-in-out;
	}*/

	.dot-loader {
		display: none;
	}

	&.is-awaiting {
		.cta-getter-label {
			opacity: 0.6;
		}
		.cue-fileformat {
			display: none;
		}
		.dot-loader {
			display: block;
		}
		.dot-small .flat-element {
			fill: currentColor;
			animation-play-state: running;
		}
	}
}

input[type='submit'].cta-getter {
	// those are used if javascript is not enabled
	@extend %_cta-getter;
}
