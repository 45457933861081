@mixin _tall-page {
	width: 100%;

	@include MEDIA-XL-and-above {
		@content;
	}

	@include MEDIA-not-tall-enough {
		height: auto;
	}

	// @include vh-polyfill(height, 100);
}

@mixin underline($color: $color-cue-success, $opacity: 1) {
	&::after {
		content: '';
		position: absolute;
		display: block;
		left: 0;
		bottom: 0;
		position: relative;
		width: 100%;
		height: 1px;
		background-color: $color;
		opacity: $opacity;
	}
}
html {
	background-color: var(--color-grade-E, $color-so-so-white);
	// most of the time populated directly within the html
}

html,
body {
	color: $dark-grey;
	margin: 0;
	padding: 0;
}

html,
body,
input {
	@extend %_font-sans-serif;
}

body {
	&.modern {
		visibility: visible;
	}

	/*
	@include links('is-block-link') {
		@extend %_links-appearance;
		display: inline-block;
		margin: 0;
		color: currentColor;
		vertical-align: middle;
		text-decoration: none;
		border: 0 solid $color-cue-latent;
		border-bottom-width: 2px;

		&::before,
		&::after {
			content: '\02009';
			display: inline-block;
		}
	}
	*/
}

a:any-link {
	color: currentColor;
	text-decoration: none;
}

div {
	margin: 0;
	padding: 0;
}

.wont-print {
	@media print {
		display: none;
		height: 0;
		visibility: hidden;
	}
}

strong {
	font-weight: 700;
}

fieldset {
	border: 0;
	margin: 0;
	padding: 0;
}

input {
	font-size: get-regular-font-size();
	vertical-align: middle;

	&::placeholder {
		font-style: italic;
	}
}

small,
time {
	@extend .use-system-sans-serif;
	font-size: get-font-size(0.76);
	color: $color-solid;
	font-weight: 300;

	@include MEDIA-mobile-breakpoint {
		color: mix($color-solid, $color-white-apex, 70%);
		&,
		&.is-clickable {
			font-weight: 700;
		}
	}
}

small {
}

time {
	display: inline-block;
}

.component {
	outline: 0;
}

ul.has-no-style {
	list-style-type: none;
	padding: 0;
	margin: 0;
}

.light-element {
	opacity: 0.3;
}

.medium-element {
	opacity: 0.5;
}

.flat-element,
.medium-element,
.light-element {
	fill: currentColor;
}

.illustration {
	visibility: hidden; // defaulted to invisible (waiting for resources, etc.)
}

.as-flex {
	display: flex;
}

.as-grid {
	display: grid;
}

.is-hidden {
	display: none;
}

.is-clickable {
	@extend %_font-sans-serif;
	@include underline($color-solid, 0.4);
	cursor: pointer;
	opacity: 1;
	transition: opacity 150ms ease-out;
	will-change: opacity;

	// color: mix( $color-white-apex, $color-solid, .5 );

	&.as-major {
		// font-weight: 700;
		// color: $color-solid;
		&::after {
			opacity: 1;
			background-color: $color-cue-success;
		}
	}

	&.is-disabled {
		cursor: default;
		opacity: 0.4;
	}
}

.is-underlined {
	@include underline;
	display: inline-block;
	line-height: 1em;
}

.is-code {
	@extend %_font-mono;
}

.as-paused {
	animation-play-state: paused;
}

.clip {
	position: absolute;
	height: 0;
	z-index: -1;
}

.common-footer {
	display: block;
	width: 100%;
	color: $grey-0;
	background-color: $color-solid;
	padding: $large-gutter;

	.⭐-h {
		@extend %_font-headings;
	}

	p,
	a {
		font-size: get-font-size(0.5);
		@extend .use-system-sans-serif;
	}
}

.⭐-h4 {
	@include MEDIA-not-tall-enough {
		font-size: get-font-size(1.5);
	}
}

#structure {
	top: 0;
	z-index: 1;

	@include MEDIA-XL-and-above {
		position: absolute;
	}

	@include MEDIA-XL-and-below {
		position: relative;
		display: flex;
		flex-direction: column;
	}

	@include _tall-page {
		height: 100%;
	}
}

rhythm-selector {
	display: block;
}
