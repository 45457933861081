.pages {
	.⭐-h {
		@extend %_font-headings;
	}

	.column,
	.abstract {
		//z-index: 2;
	}
}

.pages:not(.is-homepage) #poscat-logotype {
	@include MEDIA-XL-and-above {
		width: $column-w-size;
		height: 26px;
	}

	@include MEDIA-XL-and-below {
		width: $column-w-size;
		height: 26px;
	}
}

.⭐-p {
	@include MEDIA-L-and-above {
		max-width: $line-max-width;
	}

	@include MEDIA-L-and-below {
		max-width: $line-max-width--mobile;
	}
}
