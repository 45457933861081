.column {
	width: 50%;
	flex-direction: column;
	margin-left: $large-gutter;
	margin-top: $double-gutter;
	z-index: 2;

	@include MEDIA-XL-and-above {
		width: unquote('calc( #{$column-body-size} - #{$large-gutter} )');
	}

	@include MEDIA-L-and-above {
		position: relative;
		width: unquote('calc( #{$column-body-size--size-L} - #{$large-gutter} )');
	}

	@include MEDIA-L-and-below {
		position: initial;
		width: 100%;
		margin-bottom: $large-gutter;
	}

	&-body {
		background-color: white;
		border-radius: $half-gutter;
		padding-bottom: $double-gutter;
		border-bottom: 1px solid;
	}

	&-body-hello {
		width: 100%;
		padding: $gutter $double-gutter;
		background-color: $color-awareness-light;
		border-top-left-radius: $half-gutter;
		border-top-right-radius: $half-gutter;

		&.⭐-p {
			@include MEDIA-L-and-below {
				max-width: 100%; // overrides default star-paragraph behavior
			}
		}

		@include MEDIA-L-and-below {
			padding: $gutter;
		}
	}

	&-body-hello-name {
		padding: 3px 6px;
		background-color: $color-awareness-solid;
		border-radius: 3px;
	}

	&-title {
		margin-bottom: $gutter;
	}

	@include MEDIA-L-and-below {
		& {
			margin-left: 0;
		}

		&-header,
		&-permalink {
			//&-title {
			margin-left: $gutter;
		}

		&-title {
			background-color: var(--bg-color, transparent);
			padding: $gutter;
		}
	}
}

.is-left-column {
	max-width: $line-max-width;
}

%medallion-default-border {
	border: 1px solid $color-white;
}

.column-header {
	width: $column-w-size;
	height: $column-header-h-size;
	@extend %medallion-default-border;
	color: var(--dark-color, $grey-5);
	background-color: $color-white;
	cursor: pointer;

	&.is-smaller {
	}

	&,
	&-part {
		flex-direction: column;
		border-radius: 6px;
		border-color: var(--color-grade-E, $color-soft-overlay);
	}

	&-part {
		width: 100%;
		height: 50%;
	}

	&-chevron {
		justify-content: center;
		align-items: center;
		border-width: 0;
		border-top-width: 1px;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		@extend %medallion-default-border;
	}
}

.column-permalink {
	width: $column-w-size;
	height: 20px;
	margin-top: $large-gutter;
	justify-content: center;
	transform: translateY($half-gutter);
	z-index: 2;

	@include MEDIA-L-and-below {
		&.⭐-group {
			margin-top: unquote(
				'calc( #{$cover-h-size--small} - #{$double-gutter} - #{$column-header-h-size + 10px} )'
			);
			transform: translateY(0);
		}
	}

	& .flat-element {
		fill: $color-white;
	}
}

.column-title.⭐-group {
	@include MEDIA-L-and-below {
		margin-top: 0;
	}
}

.is-work {
	.is-meta {
		color: var(--dark-color, $color-solid);

		&--description {
			max-width: 280px; // distribution component width
		}
	}
}

.column-meta {
	color: var(--text-color, $color-solid);
	margin-top: $double-gutter;

	&.as-flex {
		flex-direction: column;
	}

	& > .is-meta {
		margin: 0 $double-gutter $half-gutter;
	}
}

.is-meta + hr {
	margin-top: $large-gutter;
}
