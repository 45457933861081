$callisto: '⭐' !default; // hey, you can name it as you see fit
$theme: 'lighter';

$precision: 100; // two digits behind comma

/*
  Font-size is base on the smallest screen length…
  … and deduced afterwards.
*/
// default root ems
$font-size-default: 16px !default; // vw units are nice, but need more fancy stuff to guard us against unexpected results
$font-size-default--print: 12pt !default;
// scales
$font-scale: 6/5 !default; // rhythm -> 6:5, minor third
$font-size-default-scale: 1 !default; // but I'd recommend you to stick with font-size-default tinkering.
// min, max
$font-size-min-threshold: 0.75 !default; // ROOT EM — what is the very minimum font-size you'd agree to?
$font-max-scale: 4 !default; // VALUE — this one is scale-based and computed later on
// infer constants
$font-size-min-factor: (1 / $font-scale - 1 - $font-size-min-threshold) *
	$font-scale;

/*
  whatever the context,
  we'd recommend to always have a min. line-height of 1.2 relative font-size
  1.2 is actually a bit arbitrary but allows a line to breathe,
  between line n descenders and n+1 ascenders.
*/
$line-max-width: 75ch !default; // this value applies to desktop
$line-max-width--mobile: 40ch !default; // this value applies to small devices
$line-max-width--headings: 40ch !default; // large size texts require narrower spaces to stay legible
$line-height: $font-scale !default;

$size-width--content: 80% !default;

/*
	The following will help, combined with media queries, to target specific devices
		e.g. "(-ms-high-contrast:none)" will target only Windows' IE11
*/
$MODIFIER--target-ie11: '(-ms-high-contrast:none)';
