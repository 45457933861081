#get-email {
	@include underline( mix( $color-solid, $color-white-apex, 30% ) );

	&::after { // underline
		transition: opacity 400ms ease-out 100ms;
		will-change: opacity;

		@include MEDIA-XL-and-above {
			left: $double-gutter;
			right: $double-gutter;
			width: unquote( "calc( 100% - #{$double-gutter * 2} )" );
		}
	}
}

input.email-input{
	width: 100%;
	background: none;
	border: 0;
}

.selector-hint-email {
	@include MEDIA-XL-and-above {
		padding-left: $double-gutter;
	}
	@include MEDIA-XL-and-below {
		padding-left: $gutter;
	}
}
