$backdrop-fill-initial-scale: 0;
$easing--ease-out-quad: cubic-bezier(0.77, 0, 0.175, 1);
$min-triangle-A-scaleY: 0.55;

@keyframes illustration-reveal-part {
	0% {
		opacity: 0;
		transform: scaleY($backdrop-fill-initial-scale);
	}
	100% {
		opacity: 1;
		transform: scaleY(1);
	}
}

@keyframes illustration-reveal-triangle-A {
	0% {
		opacity: 1;
		transform: scaleY($min-triangle-A-scaleY);
	}
	100% {
		opacity: 1;
		transform: scaleY(1);
	}
}

.illustration.reveal {
	visibility: visible;
}

.body-backdrop {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	// background-color: var(--color-grade-E, $color-so-so-white); // determined directly from commons
	z-index: 0;
}

.backdrop {
	width: 100%;
	height: auto;
	transform-origin: top center;

	@include MEDIA-XL-and-above {
		transform: scaleY(0.7);
	}

	@include MEDIA-L-and-below {
		transform: scaleY(3);
	}
}

.backdrop-no-scale {
	width: 100%;
	height: auto;
	transform-origin: top center;
	@include MEDIA-L-and-below {
		transform: scaleY(2, 0.5);
	}
}

.backdrop-fill {
	$color-backdrop-default-fill: #ffd861;
	fill: $color-backdrop-default-fill;
	opacity: 0;
	transform: scaleY($backdrop-fill-initial-scale);
	transform-origin: top center;
	will-change: opacity transform;

	&.A {
		fill: var(--color-grade-A, $color-backdrop-default-fill);
		animation: illustration-reveal-part 3s 900ms $easing--ease-out-quad forwards;
	}

	&.B {
		$color-default-B: chromatic-mix(
			$color-backdrop-default-fill,
			$color-so-so-white,
			0.3
		);
		fill: var(--color-grade-B, $color-default-B);
		animation: illustration-reveal-part 1500ms 600ms $easing--ease-out-quad
			forwards;
	}

	&.C {
		$color-default-C: chromatic-mix(
			$color-backdrop-default-fill,
			$color-so-so-white,
			0.5
		);
		fill: var(--color-grade-C, $color-default-C);
		animation: illustration-reveal-part 900ms 300ms $easing--ease-out-quad
			forwards;
	}

	&.D {
		$color-default-D: chromatic-mix(
			$color-backdrop-default-fill,
			$color-so-so-white,
			0.7
		);
		fill: var(--color-grade-D, $color-default-D);
		animation: illustration-reveal-part 300ms $easing--ease-out-quad forwards;
	}
}

.decorator-background-triangle {
	background-color: var(--opaque-background, transparent);

	.bk-triangle {
		opacity: 0;
		transform: scaleY($backdrop-fill-initial-scale);
		fill: currentColor;
	}
}

body.modern {
	.bk-triangle {
		&.C {
			fill-opacity: 0.3;
			animation: illustration-reveal-part 3.5s 0.6s $easing--ease-out-quad
				forwards;
		}

		&.B {
			fill-opacity: 0.4;
			animation: illustration-reveal-part 2.5s 0.6s $easing--ease-out-quad
				forwards;
		}

		&.A {
			opacity: 1;
			transform: scaleY($min-triangle-A-scaleY);
			animation: illustration-reveal-triangle-A 1.5s 0.6s $easing--ease-out-quad
				forwards;
		}
	}
}

.backdrop-container {
	position: absolute;
	height: 100%;

	&.is-workA {
		width: 80%;
		transform: translateY(-20%);
	}

	&.is-workB {
		width: 100%;
		transform-origin: top;
		transform: scaleY(-1) translateY(-100%); // counter the reverse background
	}
}

.backdrop-workA {
	color: var(--bg-color, transparent);
}

.backdrop-workB {
	color: var(--accent-color, transparent);
}
