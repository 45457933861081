.icon-upper-chevron,
.icon-right-chevron {
	width: 12px;
	height: 16px;

	.flat-element {
		fill: currentColor;
	}

	&.as-back {
		transform: rotate(-90deg);
	}
}
