@import 'layout/indexes';
@import 'layout/separators';

#structure.is-work {
	@extend %_structure-defaults;
}

.pages-work-elements {
	flex-direction: row;

	@include MEDIA-L-and-below {
		flex-direction: column;
	}
}

.work-title {
	@include MEDIA-L-and-below {
		@include get-heading-as(3);
	}
}

.abstract {
	$abstract-size-lazyness: 1rem;
	@extend %_font-headings;
	position: relative;
	width: $line-max-width--headings;
	margin-left: $large-gutter;
	margin-top: unquote('calc( 80vh + #{2 * $double-gutter})');
	font-size: get-font-size(2);
	align-self: flex-start;
	justify-content: flex-end;

	@include MEDIA-L-and-below {
		width: 100%;
		margin: 0;
		padding: $gutter;
		padding-bottom: 20rem; // make way to the new distribution component
		font-size: get-font-size(1);
		line-height: 1.5em;
	}

	@include MEDIA-L-and-above {
		max-width: unquote(
			'calc( #{100% - $column-body-size--size-L} + #{$large-gutter * 2} )'
		);
		margin-left: $double-gutter;
	}

	@include MEDIA-XL-and-above {
		max-width: unquote(
			'calc( #{100% - $column-body-size} + #{$large-gutter * 2} )'
		);
		margin-left: $large-gutter;
	}
}

.informative-retaste {
	@extend %_font-sans-serif;
	font-size: 1rem;
	margin-bottom: $large-gutter;
}

.column-body {
	flex-direction: column;

	@include MEDIA-L-and-below {
		margin: 0 $micro-gutter;
	}

	&-receive {
		@extend %_font-sans-serif;
		margin: $double-gutter 0 0 $double-gutter;
		@include MEDIA-L-to-XL {
			margin-left: $half-gutter;
		}
		@include MEDIA-XL-and-below {
			margin-top: $gutter;
			margin-left: $gutter;
		}
	}

	&-hello + &-calendar {
		padding-top: 0;
	}

	&-calendar > .selector-rhythm {
		@include MEDIA-L-and-below {
			padding-right: 0;
		}
	}

	.rhythm-calendar {
		@include MEDIA-XL-and-above {
			padding-left: $gutter + $half-gutter;
		}
	}

	.submit-button {
		margin-left: $double-gutter;

		@include MEDIA-L-and-below {
			margin-left: $gutter;
		}
	}
}

.ui-button {
	margin-top: $gutter;
}

.ui-button.update-rhythm-button {
	@include MEDIA-L-and-below {
		margin-left: $gutter;
	}
}

.selector-rhythm--work {
	padding: 0;
	@include MEDIA-L-and-below {
		padding-left: ($gutter - 0.4rem);
	}
}

.icon-permalink {
	cursor: pointer;
}

.is-work {
	.email-input {
		width: 100%;
		height: 2 * $half-gutter + $double-gutter;
		background-color: rgba(30, 30, 30, 0.1); //$grey-1;
		color: $color-solid;
		border: 0;
		//border: 1px solid var(--dark-color, $color-solid);
		border-radius: $half-gutter;
		padding: $gutter;

		&::placeholder {
			color: black;
		}
	}

	fieldset.is-noscript {
		padding-bottom: $double-gutter;
		@include MEDIA-XL-and-above {
			padding-left: $double-gutter;
		}
	}

	.noscript-checkboxes {
		margin: $gutter 0;
	}

	.noscript-label {
		padding-left: $half-gutter;
	}

	[type='submit'].is-noscript {
		width: 280px;
	}
}
