.work-details-stats {
	align-items: center;
}

.work-details-stats-size {
	& + & {
		margin-left: $half-gutter;
	}

	@extend %colored-flat-elements;
}
