.cue-multiplier {
	width: 20px;
	height: 20px;

	.medium-element {
		opacity: 0.8;
		stroke: currentColor;
		stroke-linecap: round;
	}
}
