/*
  _____

  CRITICAL SCAFFOLDING
  _____
*/

:root {
  @extend %typesetting;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-size: 1em; // seems counter-intuitive?
  // default font-size in an element without specificity should be considered as "minor"
  // and, as such, being smaller then element with any/higher specificity
  // any subsequent root-em defined in any child will, by the way,
  // get the **root** value and not the body one.
  font-family: sans-serif;
}

input[disabled],
button[disabled] { // as per Jake Archibald's recommendation: https://jakearchibald.com/2017/events-and-disabled-form-fields/
  pointer-events: none;
}

$size-width--as-collapsed: (
  fallback: $size-width--content * .75
  , default: unquote( "calc( #{$size-width--content} - #{$gutter} )" )
);

%size--w-minored {
  width: map-get( $size-width--as-collapsed, fallback );
  width: map-get( $size-width--as-collapsed, default );
}

%size--max-w-minored {
  max-width: map-get( $size-width--as-collapsed, fallback );
  max-width: map-get( $size-width--as-collapsed, default );
}
