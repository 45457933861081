.#{$callisto}-pre {
  @extend %size--w-minored;
  /*
    Defaults introduce changes to <pre> that we *need*
    to counteract
  */
  display: block;
  font-size: $font-size-min;
  white-space: normal;
}
