$half-gutter: 0.5em !default;
$gutter: 1em !default;
$double-gutter: 2em !default;
$line-height: 0 !default;
$color-cue-alt: blue !default;
$color-white-apex: white !default;
$color-solid: black !default;
$color-cue-latent: violet !default;

$rhythm-cue-height: 16px;
$rhythm-dot-size: 40px;

rhythm-selector {
	padding-left: $double-gutter;
}

.rhythm {
	flex-direction: row;
	background-color: white;

	&-calendar {
		padding-bottom: $half-gutter;
		flex-wrap: wrap;
	}

	&-ctrl {
		$button-padding: 4px;
		margin-top: -$button-padding;
		padding-left: $double-gutter;
		padding-right: $double-gutter;
		padding-top: 0;
		padding-bottom: $double-gutter;
		transform: translateX(-$button-padding);

		&-button {
			display: inline-block;
			padding: $button-padding;

			& + & {
				margin-left: $gutter;
			}
		}
	}

	&-label {
		margin-left: 0;
		padding: $double-gutter $double-gutter 0;
		width: 100%;
		// font-size: get-font-size( 1.5 );
		background-color: $color-white-apex;
	}

	&-oneday {
		position: relative;
		flex-direction: column;
		align-items: center;
		padding: $half-gutter 0;

		&.is-selected {
			.rhythm-oneday-label {
				color: $color-solid;
			}

			&::before {
				content: ' ';
				position: absolute;
				display: block;
				width: 100%;
				height: $rhythm-dot-size;
				left: 0;
				top: 1em + $line-height;
				background-color: #e6f6f6;
				z-index: 1;
			}
		}

		$rhythm-corners: $rhythm-dot-size / 2;
		&.is-left-rounded::before {
			border-top-left-radius: $rhythm-corners;
			border-bottom-left-radius: $rhythm-corners;
		}

		&.is-right-rounded::before {
			border-top-right-radius: $rhythm-corners;
			border-bottom-right-radius: $rhythm-corners;
		}

		&,
		&-label,
		&-cue {
			cursor: pointer;
		}

		&-label {
			@extend %_font-headings !optional;
			font-size: get-regular-font-size();
			text-transform: capitalize;
			color: $color-cue-latent;
			//padding: 0 $half-gutter;

			&.samedi,
			&.dimanche {
				color: $color-cue-alt;
			}
		}

		&-cue {
			position: relative;
			padding: $half-gutter;
			margin-top: $half-gutter;
			width: $rhythm-dot-size;
			height: $rhythm-dot-size;
			text-align: center;
			justify-content: center;
			z-index: 2;

			.svg-icon {
				height: $rhythm-cue-height;
				vertical-align: middle;
				align-self: center;
			}

			&.samedi,
			&.dimanche {
				.svg-icon-unselected {
					fill: $color-cue-alt;
				}
			}
		}
	}

	&-calendar {
		@include MEDIA-XL-and-below {
			padding-left: $half-gutter;
			padding-right: $half-gutter;
		}

		@include MEDIA-L-and-below {
			padding-left: 0;
			padding-right: 0;
		}
	}

	&-ctrl,
	&-label {
		@include MEDIA-XL-and-below {
			padding-left: $gutter + $half-gutter;
			padding-right: $gutter + $half-gutter;
		}
		@include MEDIA-L-and-below {
			padding-left: $half-gutter;
			padding-right: $half-gutter;
		}
	}
}
