html {
	// overwrites the rule from poscat-commons
	background-color: var(--accent-color, $color-so-so-white);
	// most of the time populated directly within the html
}

#structure.is-homepage {
	//flex-direction: column;
	display: grid;
	grid-template-rows: auto auto auto auto auto auto auto auto;
	grid-row-gap: 3 * $double-gutter;
	padding-top: 15%;
	z-index: 4;

	@include MEDIA-L-and-below {
		grid-template-columns: $micro-gutter 1fr $micro-gutter;
		grid-column-gap: $half-gutter;
		grid-template-areas: '. hero .' '. pitch .' '. wtf .' '. feature .' '. how .' '. community .' '. privacy .' 'footer footer footer';
		.⭐-p {
			max-width: 100%;
		}
	}

	@include MEDIA-L-and-above {
		grid-template-columns: $large-gutter $large-gutter 1fr 1fr $large-gutter $large-gutter;
		grid-column-gap: $large-gutter;
		grid-template-areas: '. hero hero hero . .' '. . pitch pitch . .' '. . wtf wtf wtf .' '. feature feature feature feature .' '. . how how . .' '. community community community community .' '. privacy privacy privacy privacy .' 'footer footer footer footer footer footer';
	}

	// for 4K screens, mostly
	// some segments may seem weird otherwise
	@include MEDIA-XXL-and-above {
		grid-template-areas: '. hero hero hero . .' '. . pitch pitch . .' '. . wtf wtf wtf .' '. feature feature feature feature .' '. . how how . .' '. . community . . .' '. . privacy . . .' 'footer footer footer footer footer footer';
	}
}

#backdrop {
	height: 100%; // thank you Safari
	background-color: var(--accent-color, transparent);
}

%_linear-gradient {
	&::after {
		content: ' ';
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(
			transparent 60%,
			var(--accent-color, transparent) 100%
		);
		z-index: 4;
	}
}

.back-cover {
	$backcover-h-size: 100vw; //1800px;
	position: absolute;
	display: block;
	width: $backcover-h-size; // 1.5 times a normal covers
	max-width: 100%;
	height: 100%; // $backcover-h-size
	overflow: hidden;

	.cover {
		right: 0;
		top: 0;
		position: absolute;
		transform-origin: right top;
		opacity: 0.6;
	}

	/*&.is-workA {
		right: 0;
		top: 0;
		//z-index: 0;

		& > .component-cover {
			transform-origin: right top;
			opacity: 0.8;
		}
	}*/

	//& > .component-cover {
	/*
	@include MEDIA-L-and-above {
		transform: scale(1.5) translate(30%, -15%);
	}
	@include MEDIA-L-and-below {
		transform: scale(1.5) translate(10%, -35%);
	}
	@include MEDIA-XXL-and-above {
		transform: scale(1.5) translate(30%, 0);
	}
	*/
	& > .cover {
		@include MEDIA-L-and-above {
			transform: scale(0.8) translate(20%, -15%);
		}
		@include MEDIA-L-and-below {
			transform: scale(0.7) translate(35%, -35%);
		}
		@include MEDIA-XXL-and-above {
			transform: scale(1.5) translate(30%, 0);
		}
	}
}

.is-homepage {
}

.rationale {
}

.hero {
	grid-area: hero;
}

.poscat {
	transform: translateX(-3px); // optic correction
}

.masthead,
.feature,
.pitch,
.pitch-extended {
	@extend %_font-headings;
}

.masthead,
.pitch {
	color: var(--dark-color, $color-solid);
}

.masthead {
	display: grid;
	place-self: center;

	grid-template-columns: $large-gutter 1fr;
	grid-template-rows: 1auto;
	grid-template-areas: 'logotype name';

	@include MEDIA-L-and-above {
		grid-column-gap: $large-gutter;
	}
	@include MEDIA-L-and-below {
		grid-column-gap: $double-gutter;
		padding: 0 $half-gutter;

		#poscat-logotype {
			width: $large-gutter;
			height: $large-gutter;
		}
	}

	z-index: 3;
	pointer-events: none;

	@include MEDIA-L-and-below {
	}

	&--logo {
		align-self: center;
	}

	&--name {
		color: var(--dark-color, $color-solid); //$color-white;
		//text-shadow: var(--shade-color, black) 0 0 5px;
		transform: translateY(-3px); // optical correction

		.⭐-h1 {
			@include MEDIA-L-and-above {
				font-size: get-font-size(3);
			}
			@include MEDIA-L-and-below {
				font-size: get-font-size(4);
			}
		}

		.⭐-p {
			margin-top: -$half-gutter;
		}
	}
}

$example-covered-area: 80%;
$example-margin: $large-gutter + $double-gutter;

.example {
	right: 0;
	z-index: 2;

	@include MEDIA-L-and-above {
		margin: $example-margin;
	}

	@include MEDIA-L-and-below {
		margin: $gutter $half-gutter;
	}
}

.pitch {
	grid-area: pitch;
}

/*
.pitch,
.homepage-main,
.community {
	@include MEDIA-L-and-below {
		margin: $half-gutter;
	}

	@include MEDIA-L-and-above {
		margin: unquote('0 calc( #{$large-gutter + $gutter} + 80px )');
	}
}
*/

.pitch .⭐-p {
	font-size: inherit;
	line-height: inherit;

	@include MEDIA-L-and-below {
		padding: 0 $half-gutter;
	}
}

.homepage-main {
	grid-area: body;
}

//.pitch-extended,
.how-does-it-work {
	grid-area: how;
	margin-bottom: $double-gutter; // compensates extra white border from the previous block
	//max-width: $line-max-width--headings;
}

.pitch-extended {
	grid-area: wtf;
	color: $color-white; //var(--dark-color, $color-solid);
	text-shadow: var(--shade-color, black) 0 0 5px;

	& > .⭐-p {
		max-width: 30ch;
		@include MEDIA-L-and-above {
			font-size: 4em;
		}

		@include MEDIA-L-and-below {
			font-size: 2em;
			padding: 0 $half-gutter;
		}
	}
}

$key-feature-size: unquote('calc(#{$line-max-width} + #{2 * $double-gutter})');

.key-features {
	grid-area: feature;
	color: var(--text-color, currentColor);

	&-explainer {
		display: grid;
		grid-row-gap: $double-gutter;
		grid-column-gap: $double-gutter;

		@include MEDIA-L-and-above {
			//margin-left: -$double-gutter;
			grid-template-areas: '. . . .';
		}
	}

	strong {
		color: var(--dark-color, $color-solid);
		//border-bottom: 1px solid var( --accent-color, transparent);
	}
}

.pitch,
.homepage-main,
.community {
	position: relative;
	z-index: 3;
}

.feature {
	//max-width: $key-feature-size;
	//min-height: 20vh; //40vh is illustrated?
	padding: $double-gutter;
	border-radius: $gutter;
	background-color: $color-white;
}

.about-content {
	.regular-link {
		display: table;
		margin-left: -$half-gutter;
		margin-top: $gutter;
	}

	&-followup {
		margin-top: $gutter;
		transform: translateX(-$half-gutter);
	}
}

@mixin any-link-content() {
	padding: $half-gutter;
	border-radius: $half-gutter;
	border: 1px solid transparent; //$color-soft-overlay;
	color: $color-white;
	text-align: center;
	font-size: 1rem;
	background-color: $black; //var(--dark-color, $color-solid);
	@content;
}

@mixin is-any-link() {
	&:any-link {
		@include any-link-content {
			@content;
		}
	}

	@supports (-ms-ime-align: auto) {
		&:link,
		&:hover,
		&:visited,
		&:active {
			@include any-link-content {
				@content;
			}
		}
	}
}

.regular-link {
	@extend %_font-headings;
	@include is-any-link;
}

.community {
	grid-area: community;
	flex-direction: column;

	&-outsourced {
		margin-top: $double-gutter;
		display: grid;
		grid-row-gap: $double-gutter;
		grid-column-gap: $large-gutter;
		place-self: flex-start;

		@include MEDIA-L-and-above {
			//margin-left: -$half-gutter;
			grid-template-areas: '. .';
			align-items: center;
		}

		@include MEDIA-L-and-below {
			//padding: $large-gutter;
			max-width: 40ch;
		}
	}

	&-outsourced-part {
		@include MEDIA-L-and-below {
			flex-direction: column;
		}

		.⭐-p {
			margin-top: $gutter;
		}
	}

	&-outsourced-link {
		font-size: 1.5em;
		align-items: flex-start;

		@include is-any-link {
			align-items: center;
			margin-right: 1em;
			background-color: var(--dark-color, $black);
		}

		@include MEDIA-L-and-above {
			margin-left: -$half-gutter;
			& + p::before {
				content: '👈 ';
			}
		}

		@include MEDIA-L-and-below {
			justify-content: center;

			& + p::before {
				content: '☝️ ';
			}
		}
	}

	.icon-3rdparty {
		//@extend %_fully-centered;
		vertical-align: middle;
		max-width: 4em;
	}
}

.how-does-it-work,
.privacy {
	column-gap: $double-gutter;
}

.privacy {
	grid-area: privacy;
	color: $black;
	margin-top: $double-gutter; // compensates extra white border from the previous block

	@include MEDIA-L-and-above {
		columns: auto 2;
	}

	@include MEDIA-L-and-below {
		columns: auto 1;
	}
}

.has-solid-bg {
	&::before {
		@include MEDIA-L-and-above {
			content: ' ';
			display: block;
			position: absolute;
			width: unquote('calc(100% + #{$large-gutter * 2})');
			height: unquote('calc(100% + #{$double-gutter * 2})');
			margin-bottom: $double-gutter; // compensates extra white border from the previous block
			transform: translate(-$large-gutter, -$double-gutter);
			background-color: $color-white;
			//background-color: var(--bg-color-light, $color-white);
			border-radius: $gutter;
			box-shadow: 0 $half-gutter $gutter var(--shade-color, rgba(0, 0, 0, 0.15));
			z-index: -1;
		}
	}

	@include MEDIA-L-and-below {
		padding: $double-gutter $gutter;
		border-radius: $gutter;
		background-color: $color-white;
		box-shadow: 0 $half-gutter $gutter var(--shade-color, rgba(0, 0, 0, 0.15));
	}
}

.how-does-it-work,
.common-footer {
	a:any-link {
		text-decoration: underline;
	}
}

.common-footer {
	//$footer-gutter: $large-gutter * 2 + $double-gutter * 2;
	grid-area: footer;
	@include MEDIA-L-and-above {
		padding: $double-gutter $large-gutter * 4;
	}
	@include MEDIA-L-and-below {
		padding: $gutter $half-gutter * 2;
	}
	//width: unquote('calc( 100% + #{$large-gutter * 2})');
	//transform: translateX(-$large-gutter);
	/*&.has-solid-bg {
		background-color: $color-solid;
	}*/

	li {
		list-style-type: disc;
	}
}

.⭐-h4 {
	color: var(--dark-color, $color-solid);
}
