/*
_____

COLORS
_____
*/

$color--default: #F710DF;

$color-white-apex: #fff !default;
$color-black-apex: #000 !default;
$color-list--context: chromatic-scale($color-black-apex, $color-white-apex, (stops: 7)) !default;

@function get-color( $which-color: 'main' ){
  @if $palette and map-has-key( $palette, $which-color ) {
    @return map-get( $palette, $which-color );
  }
  @return $color--default;
}

/*
$commons: (
'info': #6BD4F1
, 'success': #86DEB7
, 'warning': #FF9900
, 'error': #FF715B
, 'destructive': #BA7BA1
, 'darkest': #111
, 'brightest': #fff
);

$palette: map-merge( $commons, $pal ) !global;
*/
@mixin set-palette( $hue-start: #111, $hue-stop: #f9f9f9, $options: () ){
  $palette--context: chromatic-scale($hue-start, $hue-stop, $options) !global;
}

$palette--element:() !global;


/*
_____

CUSTOMIZATION STARTS HERE
_____
*/

@include set-palette();
