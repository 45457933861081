#distribution,
#distrib-docked {
	display: block;
	width: unquote('calc(100% - #{2 * $half-gutter})');
	max-width: unquote('calc( 280px + #{2 * $double-gutter})');
	color: var(--text-color, $color-solid);
	background-color: $color-white;
	overflow: hidden;

	@include MEDIA-L-and-above {
		box-shadow: 0 $half-gutter $gutter var(--shade-color, rgba(0, 0, 0, 0.15));
	}

	@include MEDIA-L-and-below {
		position: fixed;
		border-radius: $half-gutter $half-gutter 0 0;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		box-shadow: 0 $half-gutter $gutter rgba(0, 0, 0, .25);
		z-index: 3; // avoids conflict with fragment on mobile
	}
}

#distribution {
	padding: 0 0 $double-gutter;


	@include MEDIA-L-and-above {
		position: relative; // important for email confirmation block
		border-radius: $half-gutter;

		&.as-print-only.has-taste-disabled {
			padding-bottom: 0;
		}
	}

	.distribution-header {
		margin: 0;
	}

	&.as-compact {
		display: none;
	}
}

.distribution-header .icon-chevron-regular {
	@include MEDIA-L-and-above {
		display: none;
	}

	@include MEDIA-L-and-below {
		position: absolute;
		right: 1rem;
		transform: rotate(90deg) translateX(-11px);
		cursor: pointer;
	}
}

#distrib-docked {
	@include MEDIA-L-and-above {
		display: none;
	}

	@include MEDIA-L-and-below {
		display: none;
	}

	&.as-compact {
		@include MEDIA-L-and-below {
			display: block;
			padding: $double-gutter $gutter;
		}
	}
}

.distrib-docked-wrapper {
	justify-content: space-between;
	align-items: center;
}

#cta-dock {
	margin-top: 0;
}

.distrib-capabilities {
	transform: translateX(10px);
	margin-top: 2px;

	.capability {
		//color: var(--bg-color, $color-cue-alt);
		color: $color-cue-alt;

		&::before {
			display: block;
			position: absolute;
			content: ' ';
			background-color: currentColor;
			width: 32px;
			height: 32px;
			opacity: 0.2;
			margin-left: -8px;
			margin-top: -8px;
			border-radius: 50%;
		}
	}

	.is-available {
		//color: var(--text-color, $color-solid);
		color: $color-cue-success;
	}

	.capability + .capability {
		margin-left: $gutter + $half-gutter;
	}
}

.distribution-header {
	padding: $double-gutter $double-gutter 0;

	&.as-subs-only {
	}
}

.column-body-hello + .distribution-header {
	padding-top: $gutter;
}

.distrib {
	padding: $gutter 0;

	&.as-subs-only {
		padding: 0;
	}
}

.distrib-type.ctas {
	justify-content: space-between;
	max-width: 280px; // as per its major CTA counterparts
}

.distrib-part {
	padding: $gutter $double-gutter 0 $double-gutter;

	&.as-print {
		padding-bottom: $gutter;
	}

	&.as-digital {
		padding-top: $double-gutter; // fix optical issues when using a gradient backdrop
		background: linear-gradient(180deg, #efefef 0%, transparent 10%);
	}
}

.distrib-type {
	transition: opacity 150ms ease-out;

	& + & {
		margin-top: $double-gutter;
	}
}

.distrib-taste {
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0 $double-gutter;

	.cta-getter {
		width: 100%;
	}
}

.is-notice {
	padding: $half-gutter 0;
	align-items: center;

	&-label {
		margin-left: $half-gutter;
		font-style: italic;
	}
}

#agenda {
	transform: scale(1.035);
	flex-wrap: nowrap;
}

#cta-rhythm {
	color: transparent;
	&.not-pristine {
		color: $color-white;
	}
}

.subscription--shadow,
.subscription-confirmation {
	display: block;
	width: 100%;
	position: absolute;
}

.subscription--shadow {
	opacity: 0;
	pointer-events: none;
	height: 100%; // will overflow
	background-color: transparent;
	transform: translateY(-100%);
}

.subscription-confirmation {
	padding: $double-gutter $gutter;
	transform: unquote(
		'translateY(0) translateY(#{$double-gutter})'
	); // push up towards the upper part THEN substract the block padding
	background-color: $color-white;
	will-change: transform;
	transition: transform 120ms ease-out;
	border-top: 1px solid #dcdcdc;

	@include MEDIA-L-and-above {
		border-bottom-left-radius: $half-gutter;
		border-bottom-right-radius: $half-gutter;
	}

	@include MEDIA-L-and-below {
		border-top-left-radius: $half-gutter;
		border-top-right-radius: $half-gutter;
	}

	&--header {
		padding: 0 $gutter;
	}

	&--body {
		padding: $gutter;
		margin-top: $gutter;
		background-color: chromatic-mix($color-white, $color-warning, 0.1);
		border-radius: $half-gutter;
	}
}

.as-confirming {
	.distribution-header,
	.distrib-type {
		opacity: 0.4;
	}

	.subscription-confirmation {
		box-shadow: 0 0 $double-gutter #777;
		transform: unquote(
			'translateY(-100%) translateY(#{$double-gutter})'
		); // push up towards the upper part THEN substract the block padding
	}

	.subscription--shadow {
		pointer-events: all;
	}
}

#close--confirmation {
	position: absolute;
	top: $double-gutter;
	right: $gutter;
	transform: translateY(-10px);
	cursor: pointer;
}

input[type='email'] {
	padding: $half-gutter;
	border-radius: 5px;
	border-style: solid;
	width: 100%;
}
