$gutter: 1em !default;
$double-gutter: 2em !default;
$large-gutter: 4em !default;
$color-white: white !default;
$grey-5: grey !default;

$notif-size: $gutter * 3;

$animation-curve-circle: cubic-bezier(
	0.28,
	0.62,
	0.13,
	0.79
); //cubic-bezier(0.57, 0.71, 0, 0.87);

$anim-exit-fade: 0.25s;
$anim-circle-expand: 0.25s;
$anim-rect-expand: 0.25s;
$anim-msg-expand: 0.1s;
$anim-label-reveal: 0.25s;

@keyframes notifierReveal {
	0% {
		transform: scale(0);
	}
	30% {
		transform: scale(1.5);
	}
	100% {
		transform: scale(1);
	}
}

@keyframes notifierPushforward {
	0% {
		opacity: 1;
		transform: scaleX(0);
	}
	100% {
		opacity: 1;
		transform: scaleX(1);
	}
}

@keyframes notifierRevealContent {
	0% {
		opacity: 0;
		visibility: visible;
	}
	100% {
		opacity: 1;
		visibility: visible;
	}
}

@keyframes notifierPullsBack {
	0% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(-4rem);
	}
}

@keyframes notifierPushBack {
	0% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(2em);
	}
}

@keyframes notifierPushReveal {
	0% {
		opacity: 0;
		transform: translateY(2em);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

.component.notifier {
	z-index: 5;
	justify-content: space-between;

	@include MEDIA-XL-and-above {
		flex-direction: column;
		position: fixed;
		align-items: flex-end;
		right: $large-gutter;
		top: $large-gutter;
	}

	@include MEDIA-XL-and-below {
		flex-direction: column-reverse;
		position: fixed;
		align-items: center;
		left: 0;
		bottom: 0;
		width: 100%;
	}
}

.notification {
	/*position: absolute;
	top: $gutter;
	right: $gutter;
	z-index: 5;*/

	flex-direction: row;
	align-items: center;
	color: $color-white;
	cursor: pointer;
	opacity: 1;
	will-change: transform, opacity;

	&.remove {
		opacity: 0;
		pointer-events: none;

		& ~ .notification {
			@include MEDIA-XL-and-above {
				animation: notifierPullsBack 0.4s 1 ease-out;
			}

			@include MEDIA-XL-and-below {
				animation: notifierPushBack 0.4s 1 ease-in;
			}
		}
	}

	& + & {
		@include MEDIA-XL-and-below {
			margin-bottom: $half-gutter;
		}

		@include MEDIA-XL-and-above {
			margin-top: $gutter;
		}
	}

	@include MEDIA-XL-and-below {
		width: 100%;
		height: $notif-size * 1.5;
		max-height: $notif-size * 1.5;
		background-color: $grey-5;
		background-color: unquote('var(--dark-color, #{$grey-5})');
		animation: notifierPushReveal 0.08s ease-out 1 normal forwards;

		/*.svg-icon + .notifier-label {
			margin-left: $gutter;
		}*/

		.svg-decorator {
			&-circle,
			&-rect {
				fill: transparent;
			}
		}
	}

	@include MEDIA-XL-and-above {
		.notification-cue {
			transform: scale(1);
			will-change: transform;
			transform-origin: center center;
			animation: notifierReveal $anim-circle-expand 0.1s $animation-curve-circle
				1 normal forwards;
		}

		.svg-decorator-rect {
			opacity: 0;
			transform: scale(1);
			will-change: transform;
			transform-origin: 0.5em 0.5em; /* thank you Firefox… /s */
			animation: notifierPushforward $anim-rect-expand $anim-circle-expand
				ease-in 1 normal forwards;
		}

		.notifier-label {
			max-height: $notif-size;
			padding: $gutter $double-gutter $gutter $half-gutter;
			margin-left: -1px; // will avoid glitchy rendering on, in particular, IE11
			border-top-right-radius: $notif-size / 2;
			border-bottom-right-radius: $notif-size / 2;
			background-color: $grey-5;
			background-color: unquote('var(--dark-color, #{$grey-5})');
			opacity: 0;
			transform: scaleX(0);
			will-change: transform;
			transform-origin: left center;
			animation: notifierPushforward $anim-msg-expand
				($anim-rect-expand + $anim-circle-expand) ease-out 1 normal forwards;

			&-content {
				opacity: 0;
				will-change: opacity;
				animation: notifierRevealContent $anim-label-reveal
					($anim-msg-expand + $anim-rect-expand + $anim-circle-expand) ease-out
					1 normal forwards;
			}
		}

		.notifier-label {
			line-height: 1em;
		}

		.svg-decorator {
			&-circle,
			&-rect {
				fill: $grey-5;
				fill: unquote('var(--dark-color, #{$grey-5})');
			}
		}

		.svg-icon {
			&-checked,
			&-warned,
			&-errored {
				opacity: 0;
				visibility: hidden;
				animation: notifierRevealContent $anim-label-reveal
					($anim-msg-expand + $anim-rect-expand) ease-out 1 normal forwards;
			}
		}
	}

	/*@include MEDIA-XL-and-below{
		.svg-icon + .notifier-label {
			margin-left: $gutter;
		}
	}*/

	.svg-icon {
		&-checked,
		&-warned,
		&-errored {
			fill: $color-white;
		}
	}
}

.svg-icon.notification-cue {
	width: $notif-size;
	height: $notif-size;

	.light-element {
		opacity: 1;
	}
}
