#poscat-logotype {
	// .poscat-logo
	display: block;
	margin: auto;

	@include MEDIA-XL-and-above {
		width: $large-gutter;
		height: $large-gutter;
	}

	@include MEDIA-XL-and-below {
		width: $double-gutter;
		height: $double-gutter;
	}

	.white-element {
		fill: white;
	}

	.grey-element {
		fill: unquote('var(--dark-color,#{$color-solid})');
	}

	.medium-element {
		opacity: 0.4;
	}
}
