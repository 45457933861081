#work-cover,
// as individual cover
.work-cover {
	// as a collection of works
	justify-content: flex-end;
	align-items: stretch;
	flex-wrap: nowrap;
	right: 0;
	width: 100%;
	height: 100%;

	@include MEDIA-XL-and-above {
		top: 0;
	}
}

.item-cover-container {
	&,
	& > .layer {
		width: 100%; //35%;
		height: 100%;

		@include MEDIA-not-tall-enough {
			width: 60%;
		}
	}

	& > .layer {
		align-items: center;
		flex-wrap: nowrap;
		position: absolute;
		top: 0;
		right: 0;

		@include MEDIA-XL-and-above {
			margin-right: 7%;
		}
	}

	.layer-illus {
		align-self: center;
		width: 100%;
		max-width: inherit;
	}
}

#work-cover {
	position: absolute;
	z-index: 0;
}

/*
	NON LEGACY
*/

.pages.is-work {
	.cover {
		position: absolute;
		display: block;
		z-index: 1;
		left: 50%;
		transform: translateX(-50%);
		width: $page-total-h-size;
		max-width: 100%;
		height: $cover-h-size;

		@include MEDIA-not-tall-enough {
			width: 100%;
		}

		@include MEDIA-generic($MODIFIER--target-ie11) {
			width: $page-total-h-size !important;
		}

		@include MEDIA-L-and-above {
			top: $double-gutter;
		}

		@include MEDIA-L-and-below {
			height: $cover-h-size--small;
		}
	}

	svg.cover {
		top: 0;
	}
}

.decorator-background-triangle {
	position: absolute;
	height: 150%;
	z-index: 0;

	@include MEDIA-L-and-below {
		top: $cover-h-size--small;
		height: $double-gutter + $half-gutter; //20vh; //$page-total-h-size - $cover-h-size--small;
		background-color: currentColor;
	}
}
