/*
  I've been asking myself if it'd be worth having styles applied
  directly to html elements…
  As you can guess, the answer is "no".
  I cannot guarantee what your code will look like and whether if this
  particular design system will be performant under your own contraints.
  … Hence a full by-class declaration mechanism.
*/

@for $i from 1 through 6 {
  $color-heading-minus: $color-white-apex !default;
  $color-heading-maximus: $color-black-apex !default;
  $color-heading-range: 50 !default;

  .#{$callisto}-h#{$i} {
    /*
      in this template,
      h6 is set to the SAME font-size than basic elements
      like <p>, etc.

      here, "1" means "one root em" e.g. 24px
    */
    $value: set-precision( 1 + ( $font-size-max - 1 ) * ( $i - 6 ) / -5 );
    font-size: $value;

    &.#{$callisto}-as-default {
      $value: ( $color-heading-range / $i + (100 - $color-heading-range) );
      color: mix( $color-heading-maximus, $color-heading-minus, $value );
      @if $i < 3 {
        font-weight: 900;
      }
    }
  }
}

@mixin get-heading-as( $i: 1 ){
	@if $i > 6 {
		$i: 6;
	}
	$value: set-precision( 1 + ( $font-size-max - 1 ) * ( $i - 6 ) / -5 );
	font-size: $value;
}
