.icon {
	width: $gutter;
	height: $gutter;
}

.icon-size {
	width: 20px;
	height: 20px;
}

.icon-simple {
	width: 40px;
	height: 40px;
}

.icon-medium {
	width: $large-gutter;
	height: $large-gutter;
}

.cue-size + .cue-size {
	margin-left: $half-gutter;
}
