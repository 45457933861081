.hr {
	background-color: currentColor;
	height: 1px;
	opacity: 0.6;
	border: none;
	margin: 0;

	@include MEDIA-L-and-above {
		width: 100%;
		transform: translateX(-$double-gutter - $large-gutter) scaleX(0.5);
		transform-origin: top left;
	}

	@include MEDIA-L-and-below {
		width: $line-max-width--headings;
		max-width: 100%;
	}
}
