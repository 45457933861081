@keyframes decoratorFlashingStarAppliedToStar {
	0% {
		transform: scale(0);
		opacity: 0.8;
	}

	1% {
		transform: scale(0.5);
		opacity: 1;
	}

	65% {
		transform: scale(1);
		opacity: 1;
	}

	70% {
		transform: scale(0.1);
		opacity: 0;
	}
}

@keyframes decoratorFlashingStarAppliedToLensVertical {
	0% {
		transform: scaleY(0.5);
		opacity: 1;
	}
	50% {
		transform: scaleY(1);
		opacity: 1;
	}
	80% {
		opacity: 1;
	}
	100% {
		transform: scaleY(3);
		opacity: 0;
	}
}

@keyframes decoratorFlashingStarAppliedToLensHorizontal {
	0% {
		transform: scaleX(0.5);
	}
	50% {
		transform: scaleX(1);
		opacity: 1;
	}
	80% {
		opacity: 0.75;
	}
	100% {
		transform: scaleX(3);
		opacity: 0;
	}
}

%flashing-star-animations {
	.star {
		animation: decoratorFlashingStarAppliedToStar 0.3s forwards;
	}

	.lens-left,
	.lens-right {
		animation: decoratorFlashingStarAppliedToLensHorizontal 0.5s forwards;
	}

	.lens-top,
	.lens-bottom {
		animation: decoratorFlashingStarAppliedToLensVertical 0.5s forwards;
	}
}

.decorator-flashing-star {
	position: absolute; // requires its parent to be detached too
	width: 15px;
	height: 15px;
	pointer-events: none;

	&.first {
		left: 45px;
		top: 32px;
	}

	&.second {
		left: 5px;
		top: 10px;

		.lens,
		.star {
			animation-delay: 0.2s;
		}
	}

	&.third {
		left: 15px;
		top: 52px;

		.lens,
		.star {
			animation-delay: 0.35s;
		}
	}

	.star {
		fill: var(--accent-color, currentColor);
		transform-origin: center;
	}

	.lens {
		&-top {
			transform-origin: bottom center;
		}

		&-right {
			transform-origin: center left;
		}

		&-bottom {
			transform-origin: top center;
		}

		&-left {
			transform-origin: center right;
		}
	}

	.loc {
		&-top {
			fill: hotpink;
		}

		&-right {
			fill: coral;
		}

		&-bottom {
			fill: cornflowerblue;
		}

		&-left {
			fill: darkmagenta;
		}
	}
}
