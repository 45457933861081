/*hr + .work-index {
	margin-top: $large-gutter;
}*/

.as-fullindex {
	@include MEDIA-L-and-above {
		margin-top: $large-gutter;
	}

	@include MEDIA-L-and-below {
		margin-top: $double-gutter;
	}

	&.bottom-separator {
		@include MEDIA-L-and-above {
			display: none;
		}
	}
}

summary {
	//list-style-image: url(/images/icon-light-chevron--idle.svg);
	width: unquote('calc(100% - #{2 * $double-gutter})');
	padding-left: $double-gutter;
	align-items: center;
	cursor: pointer;

	&[closed='false'] {
		border: 1px solid var(--dark-color, $color-solid);
	}

	&::-webkit-details-marker {
		font-size: get-font-size(.8);
		line-height: 1rem;
		vertical-align: middle;
	}

	/*
	&::-webkit-details-marker {
		color: transparent;
		background: url(/images/icon-light-chevron--idle.svg);
		background-size: 20px 20px;
		background-position: left center;
		background-repeat: no-repeat;
	}
	*/
}

/*
details[open] {
	summary {
		list-style-image: url(/images/icon-light-chevron--opened.svg);

		&::-webkit-details-marker {
			background: url(/images/icon-light-chevron--opened.svg);
		}
	}
}
*/

.work-index {
	border-radius: $half-gutter;
	max-width: 80ch;
	color: var(--dark-color, $color-solid);

	@extend %_font-serif;

	& > .⭐-h {
		width: unquote('calc(100% - #{2 * $double-gutter})');
		max-width: 100%;
		padding: $half-gutter $double-gutter;
		font-size: 1.2em;
	}

	.⭐-h + .⭐-h {
		margin-top: 0;
	}

	li {
		font-size: get-font-size(0.8);
	}

	&-list {
		padding: 0 $double-gutter;
	}
}

.work-index-list[data-is-spoil] {
	@extend %_font-serif;
	font-weight: 400;
	font-style: italic;
}

ol {
	list-style-position: inside;

	& + & {
		margin-top: $half-gutter;
	}
}

ol[data-meta='0'],
ol[data-meta='1'] {
	margin-top: $gutter;
	font-weight: 700;
}

ol[data-meta='0'] {
	color: currentColor;
}

ol[data-meta='1'] {
	opacity: 0.8;
}

ol[data-meta='2'] {
	margin-top: $half-gutter;
	font-weight: 400;
	opacity: 0.55;
}

ol[data-meta='3'] {
	color: rgba($color-solid, 0.4);
}

ol,
li {
	list-style: none;
	padding-left: 0;
}
