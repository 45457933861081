.informative {
	color: var(--dark-color, $color-solid);

	&.⭐-group {
		@include MEDIA-L-and-below {
			padding: 0 $gutter;
		}
	}

	&-header {
		&.is-bright {
			color: $color-white;
		}

		&-link {
			padding: 0;

			&:any-link {
				color: currentColor;
				text-decoration: none;
			}
		}
	}

	&-medata {
	}

	&-retaste {
		margin-top: $large-gutter;
		flex-direction: row;
		align-items: center;
		cursor: pointer;

		@include MEDIA-L-and-above {
			margin-bottom: $double-gutter;
		}

		.informative-retaste-label {
			margin-left: $gutter;
			opacity: 0;
			transform: translateX(-10%);
			will-change: opacity transform;
			transition: ease-out 100ms;
		}

		&:hover {
			.informative-retaste-label {
				opacity: 1;
				transform: translateX(0);
			}
		}
	}
}
