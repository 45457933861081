@mixin inclusive-groups( $gutter-value: 0 ){
  padding: 0;

  &.#{$callisto}-as-standalone{
    padding-bottom: $gutter-value;
  }
}

.#{$callisto}-header {
  padding: $gutter;
  padding-top: $double-gutter;

  .#{$callisto}-h {
    width: $size-width--content;
  }
}

.#{$callisto}-group {

  & + & {
    margin-top: $double-gutter;
  }

  &--root > & {
    padding-left: $gutter;
    padding-right: $gutter;
  }

  &-s {
    @include inclusive-groups( $half-gutter );
  }

  &-xs {
    @include inclusive-groups( $gutter );
  }

  &,
  &-m {
    @include inclusive-groups( $double-gutter );
  }

  &-l {
    @include inclusive-groups( $large-gutter );
  }

  &.#{$callisto}-chapter {
    padding-bottom: 0;
  }
}

.#{$callisto}-part {
	& + & {
		margin-top: $gutter;
	}
}
