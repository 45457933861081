.single {
	position: relative;
	padding: 100px $half-gutter $gutter;
	background-position-x: center;
	display: grid;
	place-self: start;
	grid-template-rows: 1auto $double-gutter $double-gutter;
	grid-column-gap: $gutter;
	grid-row-gap: $gutter;

	&.is-regular {
		grid-template-areas: 'excerpt excerpt excerpt excerpt' 'taste taste taste taste' '. specs specs .';
		grid-template-columns: $large-gutter 1fr 1fr $large-gutter;

		@include MEDIA-L-and-above {
			width: $single-width;
		}

		@include MEDIA-L-and-below {
			width: 100%;
		}
	}

	&.is-large {
		width: 100%;

		@include MEDIA-L-and-above {
			grid-template-areas: '. . excerpt .' '. . taste .' '. . specs .';
			grid-template-columns: $large-gutter 1fr 50ch $large-gutter;
		}

		@include MEDIA-L-and-below {
			grid-template-areas: 'excerpt excerpt excerpt excerpt' 'taste taste taste taste' '. specs specs .';
			grid-template-columns: $large-gutter 1fr 1fr $large-gutter;
		}
	}

	&,
	&:before,
	&:after {
		border-radius: $gutter;
	}

	&:before,
	&:after {
		position: absolute;
		display: block;
		content: '  ';
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}

	&:before {
		background-color: var(--accent-color, $color-warm-white);
		z-index: -1;
	}

	&:after {
		background-image: linear-gradient(
			0deg,
			rgba($color-warm-white, 0.8) 0%,
			rgba($color-warm-white, 0) 75%
		);
		z-index: 0;
	}

	&-part {
		z-index: 1;
	}

	&-body {
		padding: $double-gutter;
		background-color: $color-white;
		grid-area: excerpt;
		border-radius: $half-gutter;
		cursor: pointer;
		box-shadow: 0 $half-gutter $gutter var(--shade-color, rgba(0, 0, 0, 0.15));
		transition: transform 250ms ease-out;
		z-index: 2;
	}

	&-body-title {
		color: var(--dark-color, $color-solid);
	}

	&-body,
	&-action-taste {
		transform: translateY(0);
		will-change: transform;
	}

	&-body-excerpt {
		color: var(--text-color-variant, $color-solid);
		@extend %_font-serif;
	}

	&-action-taste {
		grid-area: taste;
		background-color: var(--shade-color, rgba(0, 0, 0, 0.55));
		@include MEDIA-is-touch-only {
			background-color: $color-white;
		}
		border-radius: $gutter;
		transition: transform 200ms ease-out;

		& > .is-block-link {
			align-items: center;
			color: var(--dark-color, $color-solid);
		}
	}

	&:hover {
		.single-body {
			transform: translateY(-$double-gutter);
		}

		.single-action-taste {
			background-color: $color-white;
			transform: translateY(-$gutter);
		}
	}

	&-action-discover {
		grid-area: discover;
		align-self: start;
		justify-self: end;
	}

	&-footer {
		grid-area: specs;
		place-self: center;
		flex-direction: row;
		padding-bottom: $double-gutter;
		color: var(--dark-color, $color-solid);

		& > .is-pipe {
			margin: 0 $gutter;
		}
	}

	&.thumbnail {
		background-repeat: no-repeat;
		background-position: top center;
	}

	&.is-regular.thumbnail {
		background-size: cover;
	}

	&.is-large.thumbnail {
		background-size: contain;
	}

	.icon-popsicle-arrows {
		visibility: hidden;
	}
}

.popsicle-stars {
	position: absolute;
	width: $large-gutter;
	height: $large-gutter;
	opacity: 0;
}

.single-action-taste {
	&-link {
		width: 100%;
		justify-content: space-between;
	}

	.is-chevron {
		margin-right: $gutter;
	}

	.icon-chevron-regular {
		opacity: 0;
		animation: chevronConceal 200ms forwards;
	}
}

.single-action-taste:hover {
	.popsicle-stars {
		opacity: 1;
	}

	.lens,
	.star {
		opacity: 0;
	}

	.icon-chevron-regular {
		animation: chevronReveal 50ms 500ms forwards;
	}

	@extend %flashing-star-animations;
}

.link-to-work {
	flex-direction: column;
}
