$color-cue-success: lightseagreen !default;
$color-cue-latent: grey !default;

.pages .svg-icon {
	// width: 16px;
	// height: 16px;

	&-checked {
		fill: $color-cue-success;
	}

	&-unselected {
		fill: $color-cue-latent;
	}
}
