@function set-precision( $value ){
  @return round( $value * $precision ) / $precision;
}

// https://css-tricks.com/snippets/sass/strip-unit-function/
/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }
  @return $number;
}
