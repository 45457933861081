$gutter: 1em !default;
$double-gutter: 1em !default;

@keyframes coverRevealContent {
	0% {
		display: none;
		opacity: 0;
	}
	1% {
		display: block;
		opacity: 0;
	}
	100% {
		display: block;
		opacity: 1;
	}
}

@keyframes coverRemoveLoader {
	0% {
		display: inline-block;
		opacity: 1;
	}
	99% {
		display: inline-block;
		opacity: 0;
	}
	100% {
		display: none;
		opacity: 0;
		visibility: hidden;
	}
}

@keyframes coverRemoveLoaderDots {
	0% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(-$double-gutter);
	}
}

.component-cover {
	height: 100%;
	align-items: center;
	justify-content: center;

	/*
		hey there, IE11!
	*/
	@include MEDIA-generic($MODIFIER--target-ie11) {
		display: block;

		.dot-loader {
			position: relative;
			left: 50%;
			top: 50%;
			-ms-transform: translate(-50%, -50%);
		}
	}
}

$timeToReveal: 0.25s;

.cover {
	overflow: hidden;
}

.cover-container {
	position: absolute;
	display: none;
	left: 0;
	width: 100%;
	max-width: 100vw;
	height: 100%;
	//overflow: hidden;
	opacity: 0;
	will-change: opacity;

	&.as-noscript {
		top: 50%;
		transform: translateY(-400px); // noscript covers are 600x800 PNGs
	}

	&.displayed {
		display: block;
		opacity: 0;
		animation: coverRevealContent 1s (3 * $timeToReveal - 0.1s) ease-in 1 normal
			forwards;

		& + .dot-loader {
			animation: coverRemoveLoader (3 * $timeToReveal) ease-out 1 normal
				forwards;

			.dot-small {
				animation: coverRemoveLoaderDots (3 * $timeToReveal) ease-out;

				&.bis {
					animation-duration: (2 * $timeToReveal);
					animation-delay: $timeToReveal;
				}

				&.ter {
					animation-duration: 0.3s;
					animation-delay: (2 * $timeToReveal);
				}
			}
		}
	}
}

.cover-noscript {
	position: relative;
	left: 50%;
	transform: translateX(-50%);
}
