$color-soft-overlay: grey !default;
$soft-green: green !default;
$double-gutter: 2rem !default;
$large-gutter: 4rem !default;

$space-between-pastilles: $large-gutter;
$min-space-between-pastilles: $double-gutter;
$pastille-size: 24px;


%_check-circle-default{
	fill: none;
	stroke: $soft-green; //$color-soft-overlay;
	stroke-width: 4px;

	& + .checkmark{
		stroke-width: 0;
		opacity: 0;
	}
}

%_check-circle-checked{
	fill: $soft-green;
	stroke-width: 0;

	& + .checkmark{
		stroke-width: 2px;
		opacity: 1;
	}
}

pastille, // component name's also important
.pastille{
	display: block;
	width: $pastille-size;
	min-width: $pastille-size;
	max-width: $pastille-size; // thanx, IE.
	height: $pastille-size;
	min-height: $pastille-size;
	max-height: $pastille-size;
	cursor: pointer;
}

circle.check-circle{
	@extend %_check-circle-default;
	stroke-location: inside;
	transition: stroke-width 200ms, fill 300ms;

	&.checked{
		@extend %_check-circle-checked;
	}
}

path.checkmark{
	fill: #fff;
	stroke: $soft-green;
	stroke-width: 2px;
	transition: opacity 250ms;
}
