/*
	MQ MIXINS
*/

$mq-size-XXL: 1900px;
$mq-size-XL: 1200px;
$mq-size-L: 800px;
$mq-size-M: 600px;
$mq-size-S: 320px;

$mq-size-L--height: 690px;

@mixin MEDIA-generic($modifier: '') {
	@if $modifier != '' {
		@media screen and #{$modifier} {
			@content;
		}
	} @else {
		@media screen {
			@content;
		}
	}
}

@mixin MEDIA-is-touch-only {
	@media (hover: none) {
		@content;
	}
}

@mixin MEDIA-has-mouse {
	@media (hover: hover) {
		@content;
	}
}

@mixin MEDIA-mobile-breakpoint {
	@media screen and (max-width: $mq-size-L) and (orientation: portrait) {
		@content;
	}
	@media screen and (max-height: $mq-size-L--height) and (max-width: $mq-size-XL) and (orientation: landscape) {
		@content;
	}
}

@mixin MEDIA-XXL-and-above {
	@media (min-width: $mq-size-XXL) {
		@content;
	}
}

@mixin MEDIA-XL-and-above {
	@media (min-width: $mq-size-XL) {
		@content;
	}
}

@mixin MEDIA-XL-and-below {
	@media (max-width: ( $mq-size-XL - 1 )) {
		@content;
	}
}

@mixin MEDIA-compact {
	$l: ($mq-size-L - 1);
	$h: ($l / 2);
	@media screen and (max-width: $l) and (max-height: $h) {
		@content;
	}
}

@mixin MEDIA-L-to-XL {
	@media (min-width: ($mq-size-L)) and (max-width: ( $mq-size-XL - 1 )) {
		@content;
	}
}

@mixin MEDIA-L-and-below {
	@media (max-width: ($mq-size-L - 1)) {
		@content;
	}
}

@mixin MEDIA-L-and-above {
	@media (min-width: ($mq-size-L)) {
		@content;
	}
}

@mixin MEDIA-M-breakpoint {
	@media (min-width: $mq-size-M) and (max-width: ( $mq-size-L - 1 )) {
		@content;
	}
}

@mixin MEDIA-M-and-below {
	@media (max-width: ($mq-size-M - 1)) {
		@content;
	}
}

@mixin MEDIA-S-breakpoint {
	@media (min-width: $mq-size-S) and (max-width: ( $mq-size-M - 1 )) {
		@content;
	}
}

@mixin MEDIA-XS-breakpoint {
	@media (max-width: ( $mq-size-S - 1 )) {
		@content;
	}
}

@mixin MEDIA-not-wide-enough {
	@media (max-width: ( $mq-size-L - 1 )) {
		@content;
	}
}

@mixin MEDIA-not-tall-enough {
	@media (max-height: $mq-size-L--height) and (orientation: landscape) {
		@content;
	}
}

@mixin MEDIA-not-tall-enough--only-IE11 {
	@media (max-height: $mq-size-L--height) and (orientation: landscape) and (-ms-high-contrast: none) {
		@content;
	}
}

@mixin MEDIA-tall {
	@media (min-height: $mq-size-L--height + 1) and (orientation: landscape) {
		@content;
	}
}

@mixin MEDIA-as-landscape {
	@media (orientation: landscape) {
		@content;
	}
}

@mixin MEDIA-print {
	@media print {
		@content;
	}
}

@mixin MEDIA-iOS {
	// borrowed from https://gist.github.com/BenMorel/e9e34c08360ebbbd0634
	// and https://stackoverflow.com/questions/12539697/iphone-5-css-media-query#12848217
	$devices: (
		'2/3',
		// older iphones
			'40/71',
		// iphone5
			'375/667',
		// iphone 6
			'16/9',
		// iphone 6plus
			'3/4' // ipad
	);

	@each $device in $devices {
		$device-query: 'only screen and (-webkit-min-device-pixel-ratio: 1)';
		$device-query: '#{$device-query} and (device-aspect-ratio: #{$device})';

		@media #{$device-query} and (orientation: portrait) {
			@content;
		}
	}
}
