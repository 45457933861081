// 1. SETUP
//$font-max-scale: 4;
$font-size-min-threshold: 0.8;
$font-max-scale: 4;
@import './normalize';
@import './callisto/design/index';

// 2. SIZES
// 2.1. page
$page-total-h-size: 100vh;
// 2.2. cover
$cover-h-size: 80vh;
$cover-h-size--small: 40vh;
// 2.3. column
$column-w-size: 60px;
$column-h-size: 100%;
$column-header-h-size: 100px;
$column-body-size: 35%;
$column-body-size--size-L: 50%;
// 2.4. pages
$first-page-size-upper: 94%;
$chevron-size: 40px;

// 3. COLORS
$light-green: #d7e3de;
$bright-yellow: #eeff2b; //fffd37;
$soft-yellow: #ffff88;
$soft-green: #2bc491;
$pink: #d451ff;
$blue: #5194ec;
$error-hue: #f1a53e;
$success-hue: #bafa42;
$dark-grey: #444;
$dark-grey-2: #333;
$black: #222;

$grey-0: #fbfbfb;
$grey-1: #f2f2f2;
$grey-2: #dbdbdb;
$grey-3a: #bcbcbc;
$grey-3: #969696;
$grey-4: #555;
$grey-5: #333;
$grey-6: #222;
$grey-7: #111;

@mixin palette() {
	// main
	$color-white: white !global;
	$color-so-so-white: #fffcf4 !global;
	$color-warm-white: #fbf9f3 !global;
	$color-softgrey: #f2f2f2 !global;
	$color-solid: #393e41 !global;
	$color-success: #86deb7 !global;
	$color-help: #6bd4f1 !global;
	$color-notification: #5bc0be !global;
	$color-soft: #e7dfc6 !global;
	$color-brand: #ffd861 !global;
	$color-warning: #ff9900 !global;
	$color-error: #ff715b !global;
	$color-termination: #ba7ba1 !global;
	$color-soft-overlay: rgba($color-solid, 0.05) !global;
	// cues
	$color-cue-success: #56c0bf !global;
	$color-cue-latent: #9543ff !global;
	$color-cue-alt: #a03776 !global;
	// awareness
	$color-awareness-light: #caecec !global;
	$color-awareness-solid: #51c0c0 !global;
	// action
	$color-action: #006bcd !global;
}

$helperCueSize: 128px;

// work component (as in catalogue work component)
$single-width: unquote('calc(25ch + #{2 * ($double-gutter + $gutter)})');

// 4. MIXINS

%_fully-centered {
	left: 0;
	top: 0;
	transform: translate(-50%, -50%);
}

%_links-appearance {
	font-weight: 300;
}

%_font-headings {
	font-family: 'Haboro Soft', 'HaboroSoft', 'Roboto Condensed', sans-serif;
	font-weight: 700;
}

%_font-sans-serif {
	font-family: 'Roboto Condensed', sans-serif;
	font-weight: 300;
}

%_font-serif {
	font-family: 'Vollkorn', serif;
}

%_font-mono {
	font-family: 'Courier New', Courier, 'Lucida Sans Typewriter',
		'Lucida Typewriter', monospace;
	font-size: 1rem;
	font-style: normal;
	font-variant: normal;
	font-weight: 400;
}

%_font-size-large {
	@extend %_font-sans-serif;
	font-style: italic;
}

%_font-sans-serif-emphasis {
	@extend %_font-sans-serif;
	font-style: italic;
	font-weight: 700;
}

%_font-sans-serif-light {
	@extend %_font-sans-serif;
}

%_texts {
	@extend %_font-serif;
	text-align: justify !important;
}

%_structure-defaults {
	position: initial;
	height: auto;
	min-height: 100%;
}

%colored-flat-elements {
	.flat-element {
		fill: currentColor;
	}
}
